<template>
  <div>
    <van-nav-bar
      title="查看地块信息"
      left-text="返回"
      left-arrow
      @click-left="onClickBank"
    />
    <div id="container"></div>
  </div>
</template>

<script>
import AMap from "AMap";
import { NavBar } from "vant";
export default {
  data() {
    return {};
  },
  components: {
    VanNavBar: NavBar
  },
  methods: {
    onClickBank() {
      this.$router.go(-1);
    },
    init() {
      const latitude = this.$route.query.latitude;
      const longitude = this.$route.query.longitude;
      //   const name = this.$route.query.name;
      var that = this;
      that.map = new AMap.Map("container", {
        zoom: 12,
        center: [longitude, latitude]
      });
      var marker = new AMap.Marker({
        position: new AMap.LngLat(longitude, latitude),
        title: "这里是您选中的地块"
      });

      // 将创建的点标记添加到已有的地图实例：
      that.map.add(marker);

      var infoWindow = new AMap.InfoWindow({
        anchor: "top-left",
        content: "这是您选中的地块"
      });
      infoWindow.open(that.map, [longitude, latitude]);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
html,
body {
  width: 100vw;
  height: 100vh;
}
#container {
  width: 100vw;
  height: 100vh;
}
</style>